div + div {
    clear: both;
}
.faq-list > div {
    border-bottom: 0.07em solid #ededed;
    padding: 1.5em 0em;
} 

.faq-list > div:last-child {
    border: unset;
}

details > summary {
    list-style: none;
}
details > summary::-webkit-details-marker {
    display: none;
}

summary {
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; 
    user-select: none;
    transition: all 0.3s ease;
}

summary:hover {
    color: rgb(3, 58, 58);
}

details[open] summary ~ * {
    animation: sweep .5s ease-in-out;
}

@keyframes sweep {
    0%    {opacity: 0; margin-left: -10px}
    100%  {opacity: 1; margin-left: 55px}
}

details[open] summary {
    color: rgb(7, 7, 80);
}

details[open] p {
    border-left: 2px solid rgb(7, 7, 80);
    margin-left: 55px;
    padding-left: 25px;
    opacity: 100;
    transition: all 3s ease;
}

details[open] summary:after {
    content: "-";
    font-size: 3.2em;
    margin: -33px 0.35em 0 0;
    font-weight: 200;
}


.faq-list {
    margin: 1em auto;
    padding: 2em 0;
}

summary::-webkit-details-marker {
    display: none;
}

summary:after {
    background: transparent;
    border-radius: 0.3em;
    content: "+";
    color: rgb(7, 7, 80);
    float: left;
    font-size: 1.8em;
    font-weight: bold;
    margin: -0.3em 0.65em 0 0;
    padding: 0;
    text-align: center;
    width: 25px;
}

.faqfont{
    font-size: 15px;
}

@media screen and (min-width: 768px) {
    .faqfont{
        font-size: 18px;
    }
  }