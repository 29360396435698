.marquee {
    animation: marquee 25s linear infinite;
  }
  .marqueeX {
    animation: marqueeX 15s linear infinite;
  }

  @keyframes marquee {
    from {
      transform: translateY(50%);
    }
    to {
      transform: translateY(-100%);
    }
  }

  
  @keyframes marqueeX {
    from {
      transform: translateX(50%);
    }
    to {
      transform: translateX(-100%);
    }
  }