
/* loader-line */
.loader-line {
  height: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: cyan;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  z-index: -1;
  top: 1;
  position: fixed;
}

.loader-line:before {
  content: "";
  position: absolute;
  left: -100%;
  height: 4px;
  width: 100%;

  background: linear-gradient(0.3turn, cyan, rgb(79, 79, 235), cyan, cyan);
  -webkit-animation: lineAnim 1s linear infinite;
  -moz-animation: lineAnim 1s linear infinite;
  animation: lineAnim 1s linear infinite;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

@keyframes lineAnim {
  0% {
    left: -40%;
  }
  50% {
    left: 20%;
    width: 80%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
}
/* loaderline end */

/* text transform */

.flip {
  height: 30px;
  overflow: hidden;
}

.flip > div > div {
  color: white;
  height: 45px;
  margin-bottom: 45px;
  display: inline-block;
}

.flip div:first-child {
  animation: show 10s linear infinite;
}

@keyframes show {
  0% {
    margin-top: -270px;
  }
  5% {
    margin-top: -180px;
  }
  33% {
    margin-top: -180px;
  }
  38% {
    margin-top: -90px;
  }
  66% {
    margin-top: -90px;
  }
  71% {
    margin-top: 0px;
  }
  99.99% {
    margin-top: 0px;
  }
  100% {
    margin-top: -270px;
  }
}

/* test */

