@import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Encode+Sans+Semi+Condensed:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Tiro+Devanagari+Marathi:ital@0;1&family=Work+Sans:ital@0;1&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


.bg-all{
  background-color: #dbe8f506;
}
.font-change{
  font-family: "Roboto Condensed", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  }
  .text-size{
    font-weight: 500;
    font-style: normal;
  }
  
  .text-style{
    font-family: "Tiro Devanagari Marathi", serif;
    font-weight: 400;
    font-style: normal;
  }


 
  /* scroller */
/* width */
::-webkit-scrollbar {
    width: 7px;

}
 
/* Track */
::-webkit-scrollbar-track {
    background: #e7deff;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #110284;
    border-radius:8px
}
 
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #0d015a;
}