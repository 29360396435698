@import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Encode+Sans+Semi+Condensed:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Tiro+Devanagari+Marathi:ital@0;1&family=Work+Sans:ital@0;1&display=swap');
.VIpgJd-ZVi9od-xl07Ob-lTBxed{
    display: flex !important;
}
.goog-te-gadget-simple {
    background-color: #FFF;
    display: flex !important;
    border-radius: 17px !important;
    padding: 0 5px !important;
}
.VIpgJd-ZVi9od-vH1Gmf {
    text-decoration: none;
    overflow: hidden;
    padding: 26 26px !important;
    border-radius: 9px !important;

}
.VIpgJd-ZVi9od-ORHb {
    margin: 0;
    background-color: #E4EFFB;
    overflow: hidden;
    visibility: hidden !important;
}
.VIpgJd-ZVi9od-ORHb-OEVmcd {
    height: 45px;
    width: 100%;
    z-index: 10000001;
    position: sticky !important;
    border: none;
    border-bottom: 1px solid #6B90DA;
    box-shadow: 0 0 8px 1px #999;
    margin-top: -5.3rem !important;
    margin-bottom: 5px !important;
}
.goog-te-gadget-simple .VIpgJd-ZVi9od-xl07Ob-lTBxed {
    color: #0f0735;
    font-family: "Roboto Condensed", sans-serif !important;
    font-optical-sizing: auto !important;
    font-weight: 400 !important;
    font-style: normal !important;
}
.VIpgJd-ZVi9od-vH1Gmf {
    border-radius: 9px !important;
    padding: 0px 15px !important;
    margin-top: 4px !important;
}