.table-container {
    max-height: calc(100vh - 20px);
    overflow-y: auto;
    margin-top: 1.5rem;
    scrollbar-width: none;
    -ms-overflow-style: none;
    width: 97%;
}

.table-container::-webkit-scrollbar {
    display: none;
}


table thead th {
    position: sticky;
    top: 0;
    --tw-bg-opacity: 1;
    background-color: rgb(30 58 138 / var(--tw-bg-opacity));
    z-index: 5;
    text-align: left;
}

table {
    width: 100%;
}
